import Link from "next/link"
import Head from "next/head"

export default function Custom404() {
  return (
    <>
      <Head>
        <title>404 | Page not found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <div className="text-gray-700 mt-20 text-center">
        <h1 className="text-blue-800 mb-4">Página não encontrada</h1>
        <span>A página que você está procurando não foi encontrada.</span>
        <p className="p-2">
          <Link href="/">
            <a>Voltar para a Home</a>
          </Link>
        </p>
      </div>
    </>
  )
}

// Next.js method to ensure a static page gets rendered
export async function getStaticProps() {
  return {
    props: {},
  }
}
